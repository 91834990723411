import { AddOn, CommandStore, showRequestErrors, SOFTLINE_FEATURE_COMMANDS } from "@softline/application";
import { ConnectionResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP, Store } from "@softline/core";
import { map } from "rxjs/operators";
import { Inject, Injectable } from "@angular/core";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";
import { Router } from "@angular/router";
import { firstValueFrom, lastValueFrom, of } from "rxjs";
import {
  BelegStore,
  GesamtabfrageListComponent,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG
} from "@softapps/wws/gesamtabfrage";
import { SOFTLINE_FEATURE_MODAL, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore } from "@softline/ui-core";
import { ChangeBaustelleDialog } from "../dialogs/change-baustelle/change-baustelle.dialog";

@Injectable()
export class ChangeBaustelleAddOn implements AddOn<GesamtabfrageListComponent> {
  type = GesamtabfrageListComponent;

  constructor(private store: Store, private router: Router, @Inject(SOFTLINE_SERVICE_HTTP) private http: ResourceService<ConnectionResourceLocation>) {}

  async onAttach(host: GesamtabfrageListComponent): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: ChangeBaustelleAddOn,
        priority: -1,
        commands: [
          {
            icon: 'fas fa-pen',
            name: '#GESAMTABFRAGE.ADD_ONS.CHANGE_BAUSTELLE.TITLE',
            class: 'menu action-menu action-menu-top',
            isVisible: host.route.paramMap.pipe(map(o => o.get('type') === 'Rechnungspruefung')), /*this.store.observe(
              SOFTLINE_FEATURE_AUTHORIZATION,
              AuthorizationStore.getters.authorized,
              SOFTLINE_PERMISSION_BUCHUNGSBRIEF
            ),*/
            canExecute: host.selected$.pipe(
              map((o) => o.length === 1 && o[0].belegart.id === 'Rechnungspruefung' && !o[0].fibudatum)
            ),
            execute: async () => {
              const selected = ((await firstValueFrom(host.selected$)) as any)[0];
              if (selected.belegart.id !== 'Rechnungspruefung')
                return;

              const result = await this.store.dispatch(
                SOFTLINE_FEATURE_MODAL,
                ModalStore.actions.open<any, any>(),
                {
                  component: ChangeBaustelleDialog,
                  dismiss: true,
                  data: {
                    lieferadresse: selected.lieferadresse
                  }
                  }
                )
              if(result === 'DISMISSED')
                return;

              try {

                const httpResult = await lastValueFrom(this.http.patch({
                  path: "/abau/v1/gesamtabfrage/{{idbelegart}}/entities/{{idbeleg}}",
                  pathParams: {
                    idbelegart: selected.belegart.id,
                    idbeleg: selected.id
                  }
                }, {lieferadresse: {id: result.id}})) as any;
                this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.update, { ...selected, lieferadresse: httpResult.lieferadresse });
                await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.success, 'Die Änderung wurde übernommen')
              }
              catch (e) {
                showRequestErrors(this.store, e);
              }
            },
          },
        ],
      }
    );
  }

  async onDetach(host: any): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      ChangeBaustelleAddOn
    );
  }
}
