import { CommonModule, DecimalPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, input, Input, signal } from '@angular/core';


@Component({
  selector: 'soft-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  imports: [CommonModule, DecimalPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {

  _progress!: number;

  readonly displayValue = signal<number>(0);

  @Input()
  public set progress(progress: number) {
    if (progress < 0) {
      this._progress = 0;
      this.displayValue.set(progress);
    }
    else if (progress > 1) {
      this._progress = 1;
      this.displayValue.set(progress);
    }
    else {
      this._progress = progress;
      this.displayValue.set(progress);
    }
  }

  @Input() customClass? = '';

  allowValuesOver100Percent = input(false);
}
