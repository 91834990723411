import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { isDefined } from "@softline/core";

@Component({
  selector: 'soft-mengen-eingabe',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './mengen-eingabe.component.html',
  styleUrls: ['./mengen-eingabe.component.scss']
})
export class MengenEingabeComponent implements OnInit {
  private input: string = '';

  private wasPreviousComma = false;

  @Input() value: number = 0;
  @Input() maxValue: number | null | undefined;
  @Output() valueChange = new EventEmitter<number>();
  @Input() einheit?: string;
  @Input() float = false;
  @Input() disabled = false;
  @Input() inputClass: string | null = null;
  @Input() negativeValuesValid: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const htmlInputValue = (event.target as HTMLInputElement)?.value;
    const previousKey = htmlInputValue?.charAt(htmlInputValue.length - 1);

    if(this.disabled)
      return;
    if(event.key === 'ArrowUp') {
      this.increment()
    }
    else if(event.key === 'ArrowDown') {
      this.decrement()
    }
    else if (event.key === 'Backspace' && this.input.length >= 1) {
      this.input = this.input.substring(0, this.input.length - 1)
      this.value = +this.input;
      this.valueChange.emit(this.value);
    }
    else if (event.key === 'Backspace') {
      this.input = ''
      this.value = 0;
      this.wasPreviousComma = false;
      this.valueChange.emit(this.value);
    }
    else if(/\d/.test(event.key)) {
      if (previousKey === ',' && !(this.input.includes(',') || this.input.includes('.')) && this.float) {
        this.input = `${this.value}`
        this.input += `.${event.key}`;
      } else if (event.key !== 'Unidentified') {
        this.input += event.key;
      } else {
        event.preventDefault();
        event.stopPropagation();
      }

      let value = +this.input;

      if (this.maxValue && value > this.maxValue) {
        value = this.maxValue;
        this.input = '';
      }

      this.value = value;
      this.valueChange.emit(this.value);
    }
    else if(this.float && (event.key === '.' || event.key === ',')) {
      this.input += '.'
      let value = +this.input;

      if(this.maxValue && value > this.maxValue) {
        value = this.maxValue;
        this.input = '';
      }

      this.value = value;
      this.valueChange.emit(this.value);
    }
  }

  increment() {
    this.input = '';
    const lastValue = this.value;
    this.value++;
    if(isDefined(this.maxValue) && this.value >= this.maxValue)
      this.value = this.maxValue
    if(lastValue !== this.value)
      this.valueChange.emit(this.value);
  }

  decrement() {
    this.input = '';
    const lastValue = this.value;
    this.value--;
    if(this.value <= 0 && !this.negativeValuesValid)
      this.value = 0;
    if(lastValue !== this.value)
      this.valueChange.emit(this.value);
  }
}
